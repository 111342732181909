import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'
import { SliderFlickity } from '@dy/commons/components'
import { mq, getRelative, getP18_1_Menu } from '@/styles'
import { myUsualAsLink, packsAsLink } from './ModalMenuMobile'


interface IMenu {
  categories: [any],
  displayPacks?: boolean
}

const Ul = styled.ul<any>`
  display: none;

  ${mq.greaterThan('tablet')`
    display: block;
    margin: ${getRelative(120, 'desktop')} 0 ${getRelative(40, 'desktop')};
    padding-left: ${getRelative(25, 'desktop')};
    position: relative;
  `}

  /* ${mq.greaterThan('container')`
    margin: 120px 0 40px;
    padding-left: 25px;
  `} */

  .bar {
    ${({ $categoryBeingHovered }) => $categoryBeingHovered && css`
    a {
      opacity: .65;
      
      &:hover {
        opacity: 1;
        transform: scale(1.04) translateY(-1px);
      }
    }
    `}
    
    
    ${({ $childVisible }) => $childVisible && css`
      &:nth-child(${$childVisible}) {
        color: ${({ theme }) => theme.colors.orange};

        > a {
          opacity: 1 !important;
        }
      }
    `}
  }
`
const Li = styled(motion.li)<any>`
  ${mq.greaterThan('tablet')`
    ${getP18_1_Menu()}
    color: ${({ theme }) => theme.colors.greenlight};
    display: inline-block;
    height: ${getRelative(22, 'desktop')};
    margin-right: ${getRelative(30, 'desktop')};
    transition: 200ms color ease-out;
    width: max-content;
  `}

  /* ${mq.greaterThan('container')`
    height: 25px;
    margin-right: 30px;
  `} */

  &.bar {
    &:first-child {
      padding-left: ${getRelative(25, 'desktop')};
  
      ${mq.greaterThan('container')`
        padding-left: 25px;
      `}
    }
  
    &:nth-last-child(-n + 1)  {
      padding-right: ${getRelative(55, 'desktop')};
  
      ${mq.greaterThan('container')`
        padding-right: 55px;
      `}
    }
  }

  a {
    display: inline-block;
    opacity: 1;
    transition: 250ms all linear;

    &:hover {
      opacity: 1;
    }
  }
`

const LiDropdown = styled(motion.li)`
  background: ${({ theme }) => theme.colors.background};
  min-height: 60px;
  padding: ${getRelative(30, 'desktop')} ${getRelative(30, 'desktop')} ${getRelative(40, 'desktop')};
  position: absolute;
  width: 100vw;
  z-index: 10;

  /* ${mq.greaterThan('container')`
    padding: 30px 30px 40px;
  `} */

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      display: block;
      font-weight: normal;
      height: 25px;
      opacity: .7;
      transition: 200ms opacity ease-out;
      width: 100%;

      &:hover {
        opacity: 1;
      }
    }

    ${mq.greaterThan('tablet')`
      padding: 0 ${getRelative(5, 'desktop')};
      margin-bottom: ${getRelative(8, 'desktop')};
      width: ${100 / 3}%;
    `}

    /* ${mq.greaterThan('container')`
      padding: 0 5px;
      margin-bottom: 8px;
    `} */

    ${mq.greaterThan('desktop-md')`
      width: 25%;
    `}
  }
`

const transition = {
  ease: [.25, .1, .25, 1],
  duration: .2,
  type: 'tween'
}
const variants = {
  initial: {
    // height: 0,
    opacity: 0,
    y: '-5px',
    transition
  },
  animate: {
    // height: 'auto',
    opacity: 1,
    y: '0',
    transition
  },
  exit: {
    // height: 0,
    opacity: 0,
    y: '-5px',
    transition
  },
}

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE


const getItemList = ({ data, onMouseOver = null, triggerDropdown = true, setCategoryBeingHovered = null }) => data.map((item, idx) => {
  const { name, slug:rawSlug, subcategories } = item
  const slug = typeof rawSlug === 'string' ? rawSlug : (rawSlug[DEFAULT_LOCALE].includes('packs') && DEFAULT_LOCALE !== 'es' || rawSlug[DEFAULT_LOCALE].includes('account')) ? rawSlug[DEFAULT_LOCALE] : `/shop/${rawSlug[DEFAULT_LOCALE]}`
  return (
    <Li key={`${idx}-${slug}`} className={`${triggerDropdown ? ' bar' : ''}`} {...((triggerDropdown) && { onMouseOver: () => {
      setCategoryBeingHovered && setCategoryBeingHovered(slug)
      onMouseOver && onMouseOver(idx + 1, subcategories)
    }})}>
      <Link href={slug}>
        {typeof name === 'string' ? name : name[DEFAULT_LOCALE]}
      </Link>
    </Li>
  )
})

export const MenuSlider = memo(({ categories, displayPacks = false }:IMenu) => {
  const { data:userData }  = useCustomer()
  const userLogged = useMemo(()=> !!userData?.entityId, [userData])
  const [updatedCategories, setUpdatedCategories] = useState([...categories, ...displayPacks && DEFAULT_LOCALE !== 'es' ? [packsAsLink] : []])

  const [categoryBeingHovered, setCategoryBeingHovered] = useState(null)
  const [childVisible, setChildVisible] = useState(null)
  const [dropdownSubcategories, setDropdownSubcategories] = useState([])

  const onMouseOver = useCallback(async (position, subcategories) => {
    if (typeof position !== 'number' || !subcategories || subcategories.length < 1) {
      setChildVisible(null)
      setDropdownSubcategories([])
      return
    }
    setChildVisible(position)
    setDropdownSubcategories(subcategories)
  },[])

  const onMouseLeave = useCallback(() => {
    setChildVisible(null)
    setDropdownSubcategories([])
    setCategoryBeingHovered(null)
  },[])

  useEffect(() => {
    setUpdatedCategories([
      ...userLogged ? [myUsualAsLink] : [],
      ...categories,
      ...displayPacks && DEFAULT_LOCALE !== 'es' ? [packsAsLink] : [],

    ])
  }, [userLogged, categories, displayPacks])

  const Slides = useMemo(() => getItemList({ data: updatedCategories, onMouseOver, setCategoryBeingHovered }), [updatedCategories])

  const ListItemsSubcategories = useMemo(() => getItemList({ data: dropdownSubcategories, triggerDropdown: false }), [childVisible, onMouseOver, dropdownSubcategories])

  return (
    <Ul onMouseLeave={onMouseLeave} $categoryBeingHovered={categoryBeingHovered} $childVisible={childVisible}>
      <SliderFlickity className={'slider--menu'} loop={false} freeScroll={true} pageDots={false} cellAlign='left' groupCells={2}>
        {Slides}
      </SliderFlickity>
      <AnimatePresence>
        {childVisible  && <LiDropdown key={`subcategories-`} variants={variants} initial='initial' animate='animate' exit='exit' >
          <ul>
            {ListItemsSubcategories}
          </ul>
        </LiDropdown>}
      </AnimatePresence>
    </Ul>
  )
})
