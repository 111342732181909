"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetcherError = exports.CommerceError = void 0;
var CommerceError = /** @class */ (function (_super) {
    __extends(CommerceError, _super);
    function CommerceError(_a) {
        var message = _a.message, code = _a.code, errors = _a.errors;
        var _this = this;
        var error = message
            ? __assign({ message: message }, (code ? { code: code } : {})) : errors[0];
        _this = _super.call(this, error.message) || this;
        _this.errors = message ? [error] : errors;
        if (error.code)
            _this.code = error.code;
        return _this;
    }
    return CommerceError;
}(Error));
exports.CommerceError = CommerceError;
var FetcherError = /** @class */ (function (_super) {
    __extends(FetcherError, _super);
    function FetcherError(options) {
        var _this = _super.call(this, options) || this;
        _this.status = options.status;
        return _this;
    }
    return FetcherError;
}(CommerceError));
exports.FetcherError = FetcherError;
